<template>
    <div class="content scrollXContainer">
        <h1>{{ translate('menu.brf') }}</h1>
        <p>{{ translate('brf.description') }}</p>

        <h2>Inmatning</h2>
        <v-switch
            v-model="form.exklVAT"
            label="Inmatning exkl. moms"
            color="#ffd419"
        />
        <h3>Elpris</h3>
        <InputCell
            v-model="form.subscription.current"
            label="Nuvarande elabonnemang (kr/mån)"
            :edit="edit"
            suffix="kr/mån"
        />
        <InputCell
            v-model="form.subscription.new"
            label="Nytt elabonnemang (kr/mån)"
            :edit="edit"
            suffix="kr/mån"
        />

        <InputCell
            v-model="form.electricity.transferFee"
            label="Överföringskostnad (öre/kWh)"
            :edit="edit"
            suffix="öre/kWh"
        />
        <InputCell
            v-model="form.electricity.energyTax"
            label="Energiskatt (öre/kWh)"
            :edit="edit"
            suffix="öre/kWh"
        />
        <InputCell
            v-model="form.electricity.cost"
            label="Elpris (öre/kWh)"
            :edit="edit"
            suffix="öre/kWh"
        />
        <v-alert class="mt-4" color="#364557"
            outlined
        >
            <b>{{ numberWithSpaces(cost.sumWithoutService) }}</b> öre/kWh {{ form.exklVAT ? 'exkl. moms' : 'inkl moms' }}
        </v-alert>

        <h3>Abonnemang</h3>
        <p>Det behövs någon tjänst som övervakar hur mycket respektive hushåll laddar.</p>
        <FormulateInput
            type="text"
            label="Namn på tjänst"
            v-model="form.services[0].name"
        />
        <InputCell
            v-model="form.services[0].perUser"
            label="Pris per hushåll"
            :edit="edit"
            suffix=""
        />
        <InputCell
            v-model="form.services[0].cost"
            label="Rörlig kostnad (öre/kWh)"
            :edit="edit"
            suffix="öre/kWh"
        />

        <h3>Bostadsrättsföreningen</h3>
        <InputCell
            v-model="form.numberOfUsers"
            label="Antal hushåll som ska dela på fasta avgifter"
            :edit="edit"
            suffix=""
        />

        <h3>Förbrukning</h3>
        <p>Medelförbrukningen för en elbil för att kunna få fram rätt förbrukningssiffor</p>
        <InputCell
            v-model="form.averageConsumption"
            label="Medelförbrukning"
            :edit="edit"
            suffix=""
        />

        <h3>Betalningsmodell</h3>
        <div v-for="(model, i) in form.paymentModels" :key="i">
            <h4 v-html="model.title" />
            <p v-html="model.description" />
            <InputCell
                v-model="model.pricePerKWh"
                label=" per kWh (öre/kWh)"
                :edit="edit"
                suffix=""
            />
            <button @click="model.pricePerKWh = Math.ceil(cost.sum)">Matcha Bostadsrättsföreningens pris</button>
        </div>

        <h2>Uträkningar</h2>
        <h3>Kostnadsjämförelse per hushåll</h3>
        <p>I denna jämförelsen jämför vi vad Bostadsrättsföreningen betalar jämfört med hushållet.</p>
        <DataTable
            :data="{ result: list }"
            :showSearch="false"
            :columns="columns"
            @onSelectRow="onSelectRow"
        />

        <h3>Bostadsrättsföreningens pris</h3>
        <table class="primary">
            <tr>
                <th colspan="2">
                    <h3>Elpris</h3>
                </th>
            </tr>
            <tr>
                <th>Överföringskostnad</th>
                <td>{{ numberWithSpaces(cost.transferFee) }} <span class="suffix">öre/kWh</span></td>
            </tr>
            <tr>
                <th>Elpris</th>
                <td>{{ numberWithSpaces(cost.cost) }} <span class="suffix">öre/kWh</span></td>
            </tr>
            <tr>
                <th>Energiskatt</th>
                <td>{{ numberWithSpaces(cost.energyTax) }} <span class="suffix">öre/kWh</span></td>
            </tr>
            <template v-if="form.exklVAT">
                <tr>
                    <th>Moms 25%</th>
                    <td>{{ numberWithSpaces(cost.vat) }} <span class="suffix">öre/kWh</span></td>
                </tr>
                <tr>
                    <th colspan="2">
                        <h3>Abonnemang</h3>
                    </th>
                </tr>
                <tr>
                    <th>{{ numberWithSpaces(form.services[0].name) }}</th>
                    <td>{{ numberWithSpaces(cost.service) }} <span class="suffix">öre/kWh</span></td>
                </tr>
                <tr>
                    <th colspan="2">
                        <h3>Summa</h3>
                    </th>
                </tr>
            </template>
            <tr>
                <th>Summa</th>
                <th><h3>{{ numberWithSpaces(cost.sum) }} <span class="suffix">öre/kWh</span></h3></th>
            </tr>
        </table>

        <template v-if="this.$route.query.mail">
            <a :href="`mailto:info@voltiva.se?subject=Ändra pris för laddning&body=Hej!\nVi vill ändra priset för Brf Äpplelunden till ${numberWithSpaces(cost.sum)} öre/kWh fr o m första dagen i nästa månad.\n\nHälsningar Daniel Nord\nBrf Äpplelunden`">Skicka mail</a>
            <pre>
                Subject: Ändra pris för laddning
                Hej!

                Vi vill ändra priset för Brf Äpplelunden till {{ numberWithSpaces(cost.sum) }} öre/kWh fr o m första dagen i nästa månad.

                Hälsningar
                Daniel Nord
            </pre>
        </template>
    </div>
</template>
<script>
export default {
    data: () => ({
        edit: true,
        form: {
            exklVAT: true,
            subscription: {
                current: 420,
                new: 1580
            },
            electricity: {
                transferFee: 66.55,
                cost: 145.48,
                energyTax: 36
            },
            services: [
                {
                    name: 'Voltiva',
                    perUser: 79,
                    chosen: true,
                    cost: 19
                }
            ],
            numberOfUsers: 13,
            paymentModels: [
                {
                    title: 'Kostnadsneutral',
                    description: `I denna modellen delar alla användare på de fasta kostnadena. Var och en som har en laddstolpe delar på fasta avgifterna. Därefter betalar var och en det pris per kWh som föreningen betalar. Här linjerar utgifter med intäkter.
                                    Den fasta avgiften är ny abonnemangsavgift minus tidigare delat med antalet användare av laddstolpar`,
                    pricePerKWh: 270
                }
            ],
            averageConsumption: 180
        },
        columns: [{
            name: 'mil',
            title: 'Mil / år / hushåll'
        }, {
            name: 'consumption',
            title: 'Förbrukning / hushåll / mån'
        }, {
            name: 'costBrf',
            title: 'Kostnad för Brf / mån',
            type: 'object'
        }]
    }),
    components: {},
    mounted() {
        this.fetch()
    },
    computed: {
        list() {
            const result = []
            for (var i = 0; i <= 4000; i = i + 500) {
                const row = {
                    mil: i,
                    consumption: i / 12 * this.form.averageConsumption / 100.0
                }

                row.costBrf = row.consumption * this.cost.sum / 100.0
                row.costBrf += (this.form.subscription.new - this.form.subscription.current) / this.form.numberOfUsers
                row.costBrf += this.form.services.find(s => s.chosen).perUser

                let highlight = {
                    left: false,
                    right: false
                }
                // Add models
                this.form.paymentModels.forEach((model, j) => {
                    row[`model${j}`] = row.consumption * model.pricePerKWh / 100.0
                    row[`model${j}`] += (this.form.subscription.new - this.form.subscription.current) / this.form.numberOfUsers
                    row[`model${j}`] += this.form.services.find(s => s.chosen).perUser

                    if (row[`model${j}`] > row.costBrf * 1.01) {
                        highlight.right = true
                    } else if (row.costBrf > row[`model${j}`] * 1.01) {
                        highlight.left = true
                    }

                    row[`model${j}`] = {
                        value: `${this.numberWithSpaces(row[`model${j}`])} kr`,
                        class: highlight.right ? 'red' : null
                    }
                })

                // Format
                row.mil = this.numberWithSpaces(row.mil)
                row.consumption = `${this.numberWithSpaces(row.consumption)} kWh`
                row.costBrf = {
                    value: `${this.numberWithSpaces(row.costBrf)} kr`,
                    class: highlight.left ? 'red' : null
                }

                result.push(row)
            }
            return result
        },
        cost() {
            const result = {}
            result.transferFee = this.form.electricity.transferFee
            result.energyTax = this.form.electricity.energyTax
            result.cost = this.form.electricity.cost
            result.sumExklVAT = result.transferFee + result.energyTax + result.cost
            result.vat = this.form.exklVAT ? result.sumExklVAT * 0.25 : 0
            result.sumWithoutService = result.sumExklVAT + result.vat
            result.service = this.form.exklVAT ? this.form.services[0].cost * 1.25 : this.form.services[0].cost
            result.sum = result.sumExklVAT + result.vat + result.service
            return result
        }
    },
    methods: {
        fetch() {
            this.form.paymentModels.forEach((model, j) => {
                this.columns.push({
                    name: `model${j}`,
                    title: `${model.title} / mån`,
                    type: 'object'
                })
            })
        },
        onSelectRow(item) {
            window.location = item.url
        },
        numberWithSpaces(x) {
            if (!x && x !== 0) {
                return 'N/A'
            }
            var parts = x.toString().split('.')
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            parts[1] = parts[1] ? parts[1].substring(0, 2) : null

            return parts[1] ? parts.join(',') : parts[0]
        }
    }
}
</script>
<style scoped lang="scss">
@import "@/assets/sass/main.scss";
</style>
