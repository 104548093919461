<template>
    <div class="content">
        <v-container fluid>
            <v-row align-start>
                <v-col lg="7" cols="12" >
                    <div class="text">
                        <h2>Beräkningar</h2>
                        <p class="mt-4">En elbil är dyr i inköp men kostar mindre per mil. Ju fler mil du kör desto lättare är det att räkna hem en elbil.</p>
                        <p class="mt-4">I denna vy går det att jämföra din nuvarande bil eller en ny fossilbil kontra vad det kostar att privatleasa en elbil. Vill du räkna på banklån, personalbil eller tjänstebil rekommenderas tjänsten <a href="https://teslakalkylen.se">Teslakalkylen</a> istället.</p>
                        <v-container class="mt-4">
                            <v-row>
                                <v-col lg="4" cols="12" class="card">
                                    <h3>Inmatning</h3>
                                    <FormulateInput
                                        type="number"
                                        label="Hur många mil kör du per år?"
                                        placeholder=""
                                        v-model.number="kmDividedByTen"
                                        lazy
                                    />
                                    <FormulateInput
                                        type="number"
                                        label="Elpris per KWh⚡️"
                                        placeholder=""
                                        v-model.number="pricePerKWh"
                                        lazy
                                    />
                                    <FormulateInput
                                        type="number"
                                        label="Bränslekostnad kr/L"
                                        placeholder=""
                                        v-model.number="dinoCar.fuelCost"
                                        lazy
                                    />
                                </v-col>
                                <v-col lg="4" cols="12" class="card" >
                                    <h3>Inkludera</h3>
                                    <p>Många glömmer bort att räkna med att en bil faller i värde varje år. Vi räknar med 50% över tre år men ändra gärna utifrån hur du tror att din bil tappar i värde. Vill du bortse från värdeminskning och/eller amortering kryssar du ur dessa.</p>
                                    <FormulateInput
                                        type="checkbox"
                                        label="Amortering"
                                        placeholder=""
                                        v-model.number="calculation.include.amortization"
                                    />
                                    <FormulateInput
                                        type="checkbox"
                                        label="Värdeminskning"
                                        placeholder=""
                                        v-model.number="calculation.include.depreciation"
                                    />
                                </v-col>
                            </v-row>
                        </v-container>
                    </div>
                </v-col>
            </v-row>
        </v-container>

        <button class="ml-4" @click="edit = !edit">
            {{ edit ? 'Klar' : 'Ändra i ark' }}
        </button>
        <div class="container">
            <CarColumn
                class="first"
                key="first"
                :input="{
                    fuelCost: dinoCar.fuelCost,
                    kmDividedByTen: kmDividedByTen,
                    km: km,
                    pricePerKWh: pricePerKWh,
                    compareWith: dinoCarRealMonthlyCost(dinoCars[0])
                }"
                :calculation="calculation"
                :edit="edit"
                :firstColumn="true"
            />
            <CarColumn
                v-for="car in dinoCars"
                :key="car.id"
                :car="car"
                :input="{
                    fuelCost: dinoCar.fuelCost,
                    kmDividedByTen: kmDividedByTen,
                    km: km,
                    pricePerKWh: pricePerKWh,
                    compareWith: dinoCarRealMonthlyCost(dinoCars[0])
                }"
                :calculation="calculation"
                :edit="edit"
                :firstColumn="false"
                :hideRemoveButton="!edit"
                @removeCar="dinoCars = dinoCars.filter(c => c.id !== car.id)"
            />
            <CarColumn
                v-for="car in selectedCars"
                :key="car.id"
                :car="car"
                :input="{
                    fuelCost: dinoCar.fuelCost,
                    kmDividedByTen: kmDividedByTen,
                    km: km,
                    pricePerKWh: pricePerKWh,
                    compareWith: dinoCarRealMonthlyCost(dinoCars[0])
                }"
                :calculation="calculation"
                :edit="edit"
                :firstColumn="false"
                :hideRemoveButton="!edit"
                @removeCar="selectedCars = selectedCars.filter(c => c.id !== car.id)"
            />
            <div class="add">
                <div class="electric">
                    <div class="td title header">
                        <h3>Lägg till elbil</h3>
                        <input class="mt-6 search" type="text"
                            v-model="search"
                            placeholder="Sök efter elbil" />
                    </div>
                    <div class="car"
                        v-for="car in filteredCars.filter(car => !selectedCars.map(selected => selected.id).includes(car.id))" :key="car.id"
                        @click="selectCar(car)">
                        <v-card-title
                            class="text-h5 pa-0"
                        >
                            <template v-if="car.model.length <= 4">{{ car.make }} </template>{{ car.model }}
                        </v-card-title>

                        <v-card-subtitle class="pt-4 pl-1">
                            {{ car.make }} {{ car.model }} {{ car.version }}
                        </v-card-subtitle>
                        <v-avatar>
                            <img
                                :src="`cars/${car.img}`"
                                :alt="`${car.model} ${car.model}`"
                                class="carAvatar"
                            >
                        </v-avatar>
                    </div>
                </div>
                <div class="pa-2">
                    <button @click="addDinoCar()">Lägg till fossilbil</button>
                </div>
            </div>
        </div>
        <v-alert class="mt-4" color="#364557"
            outlined
        >
            Kontrollräkna din kalkyl. Vi tar inte ansvar över eventuella felräkningar.
        </v-alert>
    </div>
</template>

<script>
import Draggable from 'vuedraggable'
import { mapGetters } from 'vuex'
import { Guid } from '@/helpers'
const guid = new Guid()
export default {
    data: () => ({
        edit: false,
        settingValues: false,
        kmDividedByTen: 1500,
        pricePerKWh: 1.5,
        dinoCar: {
            fuelCost: 16.5
        },
        calculation: {
            include: {
                amortization: true,
                depreciation: true
            }
        },
        search: null,
        selectedCars: [],
        dinoCars: [ /*, {
            id: 2,
            dino: true,
            title: 'Audi A5',
            consumption: 0.8,
            fuelCost: 16,
            tax: 1196,
            service: 3823,
            leasing: {
                price: 0,
                wheels: 0,
                insurance: 0
            },
            wheels: 6000, // 36 months
            insurance: 828,
            value: 200000,
            amortization: 1666,
            residualValue: 50
        } */]
    }),
    components: { Draggable },
    mounted() {
        this.parseQueryString()
        this.edit = false
    },
    computed: {
        ...mapGetters({
            list: 'cars/list'
        }),
        cars: {
            get() {
                return this.list
            },
            set(value) {
                return this.$store.commit('cars/set', value)
            }
        },
        km() {
            return this.kmDividedByTen * 10
        },
        isSafari() {
            var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
            return isSafari
        },
        filteredCars() {
            return this.filterAndSort(this.cars, this.search, 'make', false)
        }
    },
    methods: {
        onSelectRow(item) {
            this.$router.push({
                path: `/cars/${item.id}`
            })
        },
        setDriven(item, driven) {
            this.$set(item, 'driven', driven)
            this.$store.dispatch('cars/update', { id: item.id, model: item })
        },
        summarizeMonthlyCost(car) {
            if (car.dino) {
                return car.wheels + (car.consumption * this.dinoCar.fuelCost / 100 * this.km / 10 / 12) + car.leasing.insurance + car.service + car.tax / 12
            }
            return car.leasing.price + car.leasing.wheels + (car.consumption.current * this.pricePerKWh / 100 * this.km / 10 / 12) + car.leasing.insurance + car.service + car.tax / 12
        },
        dinoCarMonthlyCost(dinoCar) {
            if (!dinoCar) {
                return 0
            }
            return dinoCar.leasing.price + (dinoCar.wheels / 36) + (dinoCar.consumption * this.dinoCar.fuelCost * this.km / 10 / 12) + dinoCar.insurance + (dinoCar.service / 12) + (dinoCar.tax / 12)
        },
        dinoCarRealMonthlyCost(dinoCar) {
            if (!dinoCar) {
                return 0
            }
            let sum = this.dinoCarMonthlyCost(dinoCar)
            if (this.calculation.include.amortization) {
                sum += dinoCar.amortization
            }

            if (this.calculation.include.depreciation) {
                const depreciation = dinoCar.value * (100 - dinoCar.residualValue) / 100 / 36
                sum += depreciation > dinoCar.amortization ? depreciation - dinoCar.amortization : 0 // Only count depreciation if amortization is less than depreciation
            }
            return sum.toFixed(2)
        },
        addDinoCar() {
            this.dinoCars.push({
                id: guid.generate(),
                dino: true,
                title: null,
                consumption: 0.8,
                tax: 2000,
                service: 5000,
                leasing: {
                    price: 0,
                    wheels: 0,
                    insurance: 0
                },
                wheels: 6000, // 36 months
                insurance: 800,
                value: 200000,
                amortization: 1666,
                residualValue: 50
            })
        },
        selectCar(car) {
            this.edit = true
            const c = { ...car }
            this.$set(c.consumption, 'current', c.consumption.real.vehicle)
            this.selectedCars.push(c)
        },
        filterAndSort(objects, search, orderBy, ascending) {
            if (!search) {
                return objects
            }
            var result = objects.filter(function(o) {
                for (var key in o) {
                    if ((o[key] + '').toLowerCase().indexOf(search.toLowerCase()) > -1) {
                        return true
                    }
                }
            })

            if (orderBy) {
                return result.sort((a, b) => {
                    const asc = (ascending ? 1 : -1)
                    const ao = a[orderBy]
                    const bo = b[orderBy]
                    if (!ao && !bo) {
                        return 0
                    } else if (!ao) {
                        return -1 * asc
                    } else if (!bo) {
                        return 1 * asc
                    }

                    if (typeof ao === 'number') {
                        return (ao - bo) * asc
                    } else if (typeof ao === 'object') {
                        return asc * (ao.value + '').localeCompare(bo.value + '')
                    }
                    return asc * (ao + '').localeCompare(bo + '')
                })
            }
            return result
        },
        addFirstParamsToLocation() {
            const params = {
                ps: JSON.stringify({
                    km10: this.kmDividedByTen,
                    pkwh: this.pricePerKWh,
                    fc: this.dinoCar.fuelCost
                })
            }
            const query = { ...this.$route.query, ps: params.ps }
            if (query.ps === this.$route.query.ps) {
                return
            }
            this.settingValues = true
            this.$router.replace({ query })
            localStorage.setItem('ps', query.ps)
            this.settingValues = true
        },
        addParamsToLocation() {
            if (!this.edit) {
                return
            }
            const params = {
                dc: JSON.stringify(this.dinoCars.map(car => {
                    const row = {
                        t1: car.title,
                        v: car.value,
                        c: car.consumption,
                        t: car.tax,
                        s: car.service,
                        w: car.wheels,
                        i: car.insurance,
                        a: car.amortization
                    }
                    return row
                })),
                ec: JSON.stringify(this.selectedCars.map(car => {
                    const row = {
                        id: car.id,
                        c: car.consumption.current,
                        t: car.tax,
                        s: car.service,
                        l: car.leasing.price,
                        w: car.leasing.wheels,
                        i: car.leasing.insurance
                    }
                    return row
                }))
            }
            const query = { ...this.$route.query, dc: params.dc, ec: params.ec }
            if (query.dc === this.$route.query.dc && query.ec === this.$route.query.ec) {
                return
            }
            this.$router.replace({ query })
            localStorage.setItem('dc', query.dc)
            localStorage.setItem('ec', query.ec)
        },
        parseQueryString() {
            let dc = null
            if (this.$route.query.dc) {
                // Restore
                dc = JSON.parse(this.$route.query.dc)
            } else {
                dc = JSON.parse(localStorage.getItem('dc'))
            }

            if (dc) {
                this.dinoCars = []
                dc.forEach((c, i) => {
                    this.addDinoCar()

                    this.dinoCars[i].title = c.t1
                    this.dinoCars[i].value = c.v
                    this.dinoCars[i].consumption = c.c
                    this.dinoCars[i].tax = c.t
                    this.dinoCars[i].service = c.s
                    this.dinoCars[i].wheels = c.w
                    this.dinoCars[i].insurance = c.i
                    this.dinoCars[i].amortization = c.a
                })
            }

            let ec = null
            if (this.$route.query.ec) {
                // Add selectedCars
                ec = JSON.parse(this.$route.query.ec)
            } else {
                ec = JSON.parse(localStorage.getItem('ec'))
            }

            if (ec) {
                ec = [...new Set(ec)]
                const uniqueIds = [...new Set(ec.map(c => c.id))]
                uniqueIds.forEach(id => {
                    const car = this.cars.find(c2 => c2.id === id)
                    this.selectCar(car)
                })

                // Set values
                this.selectedCars.forEach(car => {
                    const c = ec.find(c2 => c2.id === car.id)
                    if (!c) {
                        return
                    }
                    car.consumption.current = c.c
                    car.tax = c.t
                    car.service = c.s
                    car.leasing.price = c.l
                    car.leasing.wheels = c.w
                    car.leasing.insurance = c.i
                })
            } else {
                this.dinoCars = []
                this.selectedCars = []
                this.dinoCars.push({
                    id: 1,
                    dino: true,
                    title: 'Min nuvarande bil',
                    consumption: 0.8,
                    fuelCost: 16,
                    tax: 3762,
                    service: 5000,
                    leasing: {
                        price: 0,
                        wheels: 0,
                        insurance: 0
                    },
                    wheels: 6000, // 36 months
                    insurance: 680,
                    value: 150000,
                    amortization: 2084,
                    residualValue: 50
                })
                this.selectCar(this.cars.find(car => car.id === 'da7d7d75-f947-463c-bb9d-db6ab7e0fa1a'))
            }

            let ps = null
            if (this.$route.query.ps) {
                // Restore
                ps = JSON.parse(this.$route.query.ps)
            } else {
                ps = JSON.parse(localStorage.getItem('ps'))
            }

            if (ps) {
                this.kmDividedByTen = ps.km10
                this.pricePerKWh = ps.pkwh
                this.dinoCar.fuelCost = ps.fc
            }
        }
    },
    watch: {
        dinoCars: {
            handler(to, from) {
                this.addParamsToLocation()
            },
            deep: true
        },
        selectedCars: {
            handler(to, from) {
                this.addParamsToLocation()
            },
            deep: true
        },
        kmDividedByTen: {
            handler(to, from) {
                this.addFirstParamsToLocation()
            }
        },
        pricePerKWh: {
            handler(to, from) {
                this.addFirstParamsToLocation()
            }
        },
        'dinoCar.fuelCost': {
            handler(to, from) {
                this.addFirstParamsToLocation()
            }
        },
        '$route': {
            handler(to, from) {
                if (this.edit || this.settingValues) {
                    return
                }
                this.parseQueryString()
            }
        }
    }
}
</script>
<style scoped lang="scss">
@import "@/assets/sass/main.scss";
@import "@/assets/sass/formulate.scss";

.text
{
  position: relative;
}

.text h2
{
  font-size: 2em;
  font-weight: 600;
  color: $black;
  line-height: 1em;
  text-transform: uppercase;
}
.text h3
{
  font-size: 1.4em;
  font-weight: 400;
  color: $black;
  line-height: 1em;
  padding: 16px 0px;
  text-transform: uppercase;
}
.text p
{
  font-size: .9em;
  color: $black;
  margin: 4px 0;
  font-weight: 400;
  max-width: 700px;

}

.card {
    position: relative;
    background: #fafafa !important;
    padding: 4px 30px;
    color: #111;
    transition: 0.2s;
    min-height: 320px;
    border-left: thick double #364557;
    box-shadow:  3px 3px 7px #d4d4d4,
            -3px -3px 7px #ffffff;
    margin-right: 16px;
    margin-bottom: 8px;
}

.notDriven {
    opacity: 0.5;
}

.carAvatar {
    width: 90px;
    height: auto;
}

th, td {
    min-width: 200px;
}

/* Sticky column and rows START */
section {
    min-width: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, .5);
        box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
}
table {
    table-layout: fixed;
    position: relative;
}

table thead tr {
    th:nth-child(1) {
        z-index: 101 !important;
    }
    th {
        position: sticky;
        z-index: 100 !important;
        background: white;
    }

    &:nth-child(1) th {
        top: 0px;
    }
}

table thead, table tbody, table tfoot {
    th, td {
        position: relative;
        z-index: 99;
    }

    >:first-child {
        position: sticky;
        left: 0;
        z-index: 100 !important;
    }
}
table thead :first-child, table tbody :first-child, table tfoot :first-child {
    position: sticky;
    left: 0;
    z-index: 100 !important;
}
/* Sticky column and rows END */

table {
    min-width: 100%;

    th, td {
        height: 20px;
    }

    tr:empty {
        height: 10px;
    }
}

.car {
    overflow: hidden;
    border: 1px solid rgba(135, 154, 161, 0.5);
}

.add {
    scroll-snap-align: start;
    min-width: 280px;
    margin: 2px;
    border: 1px solid rgba(135, 154, 161, 0.5);
    cursor: pointer;
    .electric {
        overflow-y: auto;
            border-radius: 9px;
        height: 1070px;
        >div.header {
            height: 36px;
            overflow: hidden;
            &.title {
                text-align: center;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='279' height='160' preserveAspectRatio='none' viewBox='0 0 279 160'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1093%26quot%3b)' fill='none'%3e%3crect width='279' height='160' x='0' y='0' fill='rgba(233%2c 239%2c 241%2c 1)'%3e%3c/rect%3e%3cpath d='M19 160L179 0L276.5 0L116.5 160z' fill='url(%23SvgjsLinearGradient1094)'%3e%3c/path%3e%3cpath d='M109.07000000000001 160L269.07 0L341.07 0L181.07 160z' fill='url(%23SvgjsLinearGradient1094)'%3e%3c/path%3e%3cpath d='M269 160L109 0L85 0L245 160z' fill='url(%23SvgjsLinearGradient1095)'%3e%3c/path%3e%3cpath d='M168.93 160L8.930000000000007 0L-129.07 0L30.930000000000007 160z' fill='url(%23SvgjsLinearGradient1095)'%3e%3c/path%3e%3cpath d='M141.95647876038842 160L279 22.95647876038842L279 160z' fill='url(%23SvgjsLinearGradient1094)'%3e%3c/path%3e%3cpath d='M0 160L137.04352123961158 160L 0 22.95647876038842z' fill='url(%23SvgjsLinearGradient1095)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1093'%3e%3crect width='279' height='160' fill='white'%3e%3c/rect%3e%3c/mask%3e%3clinearGradient x1='0%25' y1='100%25' x2='100%25' y2='0%25' id='SvgjsLinearGradient1094'%3e%3cstop stop-color='rgba(198%2c 208%2c 211%2c 1)' offset='0'%3e%3c/stop%3e%3cstop stop-opacity='0' stop-color='rgba(198%2c 208%2c 211%2c 1)' offset='0.66'%3e%3c/stop%3e%3c/linearGradient%3e%3clinearGradient x1='100%25' y1='100%25' x2='0%25' y2='0%25' id='SvgjsLinearGradient1095'%3e%3cstop stop-color='rgba(198%2c 208%2c 211%2c 1)' offset='0'%3e%3c/stop%3e%3cstop stop-opacity='0' stop-color='rgba(198%2c 208%2c 211%2c 1)' offset='0.66'%3e%3c/stop%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e") !important;
                height: 160px;
                color: $main_2;
                padding-top: 16px;
            }
        }
    }

    background: #fff !important;
    border-radius: 9px;
    background: #f9f9f9;

    .car {
        border-radius: 9px;
        background: #f9f9f9;
        box-shadow:  7px 7px 14px #d4d4d4,
                    -7px -7px 14px #ffffff;
        padding: 8px;
        margin: 12px;

        &:hover {
            background: #efefef !important;
        }
    }
}

@media only screen and (max-width: 600px) {
    .sheet {
        padding: 0 !important;
        background: transparent;
    }

    table thead tr {
        th {
            position: relative;
        }
    }

    table thead, table tbody, table tfoot  {
            >:first-child {
            position: relative;
        }
    }
    table thead :first-child, table tbody :first-child, table tfoot :first-child {
        position: relative;
    }
}

.container {
    scroll-snap-type: x mandatory;
    max-width: unset !important;
    display: flex;
    overflow-x: auto;
    @media only screen and (max-width: 600px) {
        .first {
            display: none;
        }
    }
}

p {
    max-width: 480px;
}

</style>
