import { render, staticRenderFns } from "./Sheet.vue?vue&type=template&id=6370f96c&scoped=true&"
import script from "./Sheet.vue?vue&type=script&lang=js&"
export * from "./Sheet.vue?vue&type=script&lang=js&"
import style0 from "./Sheet.vue?vue&type=style&index=0&id=6370f96c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6370f96c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VAvatar,VCardSubtitle,VCardTitle,VCol,VContainer,VRow})
