var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('h1',[_vm._v(_vm._s(_vm.translate('menu.apps')))]),_c('p',[_vm._v(_vm._s(_vm.translate('apps.description')))]),_c('div',{staticClass:"scrollXContainer"},[_c('DataTable',{attrs:{"data":{ result: _vm.list },"showSearch":false,"columns":[{
                name: 'title',
                title: _vm.translate('apps.columns.title'),
                width: '250px'
            }, {
                name: 'url',
                title: _vm.translate('apps.columns.url'),
                width: '220px'
            }, {
                name: 'description',
                title: _vm.translate('apps.columns.description'),
                class: 'selectable'
            }]},on:{"onSelectRow":_vm.onSelectRow}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }