<template>
    <div class="content pa-6">
        <h1>{{ car.make }} {{ car.model }} {{ car.version }}</h1>
        <CarChart-Overview :car="car" />

        <div style="height: 500px"></div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    data: () => ({
        data: null,
        dark: true
    }),
    components: {},
    async mounted() {},
    computed: {
        ...mapGetters({
            list: 'cars/list'
        }),
        car() {
            return this.list.find(c => c.id === this.$route.params.id)
        }
    },
    methods: {}
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/sass/main.scss";
</style>
