<template>
    <div class="content">
        <h1>{{ translate('menu.apps') }}</h1>
        <p>{{ translate('apps.description') }}</p>
        <div class="scrollXContainer">
            <DataTable
                :data="{ result: list }"
                :showSearch="false"
                :columns="[{
                    name: 'title',
                    title: translate('apps.columns.title'),
                    width: '250px'
                }, {
                    name: 'url',
                    title: translate('apps.columns.url'),
                    width: '220px'
                }, {
                    name: 'description',
                    title: translate('apps.columns.description'),
                    class: 'selectable'
                }]"
                @onSelectRow="onSelectRow" />
        </div>
    </div>
</template>
<script>
export default {
    data: () => ({
        list: [{
            title: 'A Better Route Planner',
            url: 'https://abetterrouteplanner.com/',
            description: 'Se var och hur länge du behöver ladda på långresa för att optimera din restid',
            class: 'selectable'
        }, {
            title: 'ChargeFinder',
            url: 'https://chargefinder.com/',
            description: 'Hitta laddare samt se priser på de flesta av dem',
            class: 'selectable'
        }, {
            title: 'Teslakalkylen',
            url: 'https://teslakalkylen.se/',
            description: 'Räkna på mer avancerade scenarion än på denna sidan. Inte bara för Tesla',
            class: 'selectable'
        },
        {
            title: 'EV-Database',
            url: 'https://ev-database.org/',
            description: 'En databas med elbilar',
            class: 'selectable'
        }]
    }),
    components: {},
    mounted() {},
    computed: {},
    methods: {
        onSelectRow(item) {
            window.location = item.url
        }
    }
}
</script>
<style scoped lang="scss">
@import "@/assets/sass/main.scss";

</style>
