<template>
    <div class="addCar pa-6">
        <h1>Lägg till bil</h1>
        <FormulateForm class="formulate-input">
            <div class="group">
                <FormulateInput
                    type="text"
                    label="Bilmärke"
                    placeholder="Bilmärke på bilen"
                    v-model="item.make"
                />
                <FormulateInput
                    type="text"
                    label="Modellnamn"
                    placeholder="Model 3, Zoe etc."
                    v-model.number="item.model"
                />
                <FormulateInput
                    type="text"
                    label="Version"
                    placeholder="LR, short range eller dylikt"
                    v-model.number="item.version"
                />
            </div>

            <FormulateInput
                type="checkbox"
                label="Jag har kört den"
                placeholder="Kryssa i ifall du kört bilen"
                v-model.number="item.driven"
            />

            <h2>Drivlina</h2>
            <h3>Batteri</h3>
            <FormulateInput
                type="number"
                label="Användbar batteristorlek"
                placeholder="Antal kWh batteriet har vid 100% laddning"
                v-model.number="item.battery.usable"
            />
            <h3>Laddning</h3>
            <FormulateInput
                type="number"
                label="Maxhastighet"
                placeholder="Högsta hastigheten bilen kan ladda i"
                v-model.number="item.charging.max"
            />
            <h4>Kurva</h4>
            <button @click="item.charging.curves[0].data.push({
                time: null,
                power: null,
                percentage: null
            })">Lägg till tidpunkt på kurva</button>
            <div class="group"
                v-for="(c, i) in item.charging.curves[0].data"
                :key="`curve${i}`"
            >
                <FormulateInput
                    type="number"
                    label="Tid i minuter"
                    placeholder="Hur lång tid sedan laddning påbörjades"
                    v-model.number="c.time"
                />
                <FormulateInput
                    type="number"
                    label="Effekt kWh"
                    placeholder="Effekt på laddning"
                    v-model.number="c.power"
                />
                <FormulateInput
                    type="number"
                    label="Procent laddad"
                    placeholder="Högsta hastigheten bilen kan ladda i"
                    v-model.number="c.percentage"
                />
            </div>

            <h2>Förbrukning</h2>
            <FormulateInput
                type="number"
                label="WLTP Wh/km"
                placeholder=""
                v-model.number="item.consumption.wltp"
            />
            <FormulateInput
                type="number"
                label="EPA Wh/km"
                placeholder=""
                v-model.number="item.consumption.epa"
            />
            <FormulateInput
                type="number"
                label="Förbrukning vid 90km/h (Wh/km)"
                placeholder="Wh/km"
                v-model.number="item.consumption.speed90"
            />
            <FormulateInput
                type="number"
                label="Förbrukning vid 110km/h (Wh/km)"
                placeholder="Wh/km"
                v-model.number="item.consumption.speed120"
            />
            <FormulateInput
                type="number"
                label="Vinterförbrukning"
                placeholder="Värde att multiplicera med. Extra förbrukning under 6°C jämfört med 20°C."
                v-model.number="item.consumption.winterMultiplicator"
            />

            <h2>Kostnader</h2>
            <FormulateInput
                type="number"
                label="Inköpspris"
                placeholder=""
                v-model.number="item.price"
            />

            <h3>Försäkring och service</h3>
            <FormulateInput
                type="number"
                label="Försäkring / år"
                placeholder=""
                v-model.number="item.tax"
            />

            <FormulateInput
                type="number"
                label="Service / år"
                placeholder="Eventuella servicekostnader utslaget per månad"
                v-model.number="item.service"
            />

            <h3>Leasing</h3>
            <FormulateInput
                type="number"
                label="Leasingkostnad / mån"
                placeholder=""
                v-model.number="item.leasing.price"
            />
            <FormulateInput
                type="number"
                label="Försäkring per år"
                placeholder=""
                v-model.number="item.leasing.insurance"
            />
            <FormulateInput
                type="number"
                label="Däckskostnad / mån"
                placeholder=""
                v-model.number="item.leasing.wheels"
            />
        </FormulateForm>
        <button @click="add">Lägg till</button>
    </div>
</template>
<script>
import { Guid } from '@/helpers'
const guid = new Guid()
export default {
    data: () => ({
        item: {
            make: null,
            model: null,
            version: null,
            tags: null,
            img: null,
            color: null,
            driven: false,
            battery: {
                real: null,
                usable: null
            },
            charging: {
                max: null,
                curves: [{
                    source: null,
                    charger: {
                        max: 150,
                        where: null
                    },
                    data: [{
                        time: 0,
                        power: null,
                        percentage: 0
                    }, {
                        time: null,
                        power: null,
                        percentage: 50
                    }, {
                        time: null,
                        power: null,
                        percentage: 80
                    }]
                }]
            },
            consumption: {
                wltp: null,
                epa: null,
                speed90: null,
                speed120: null,
                winterMultiplicator: 1.2
            },
            price: null,
            leasing: {
                price: null,
                wheels: null,
                insurance: null
            },
            tax: 315,
            service: 0
        }
    }),
    components: {},
    mounted() {},
    computed: {},
    methods: {
        async add() {
            this.item.id = guid.generate()
            await this.$store.dispatch('cars/add', this.item)
        }
    }
}
</script>
<style scoped lang="scss">
@import "@/assets/sass/main.scss";
.addCar {
    background: $white;
}
</style>
